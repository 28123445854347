import React from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import FeatureContainer from '../../components/FeatureContainer';
import { signUp } from '../../utils/contentProvider/function';
import TrustedBy from '../../components/TrustedBy';

import TestimonialsSlider from '../../components/TestimonialsSlider/TestimonialsSlider';
import 'styles/usecases.scss';
import NewFaq from '../../components/NewFaq/NewFaq';

const faqData = [
  {
    id: nanoid(),
    q: 'How to use ruttl?',
    a: 'Sign up, then create a project. Paste your website URL and add it to this project. Share the project with your team by adding their email addresses. Once the page loads on ruttl, it is in comment mode by default. Click to add comments, tag other team members in comments using ‘@’, resolve or delete comments. Switch to edit mode to move elements, replace images, edit text, change background colours, modify opacities, etc. These edits can be resolved once done, thereby speeding up your web review process.',
  },
  {
    id: nanoid(),
    q: 'Can developers access website code after design changes are executed?',
    a: 'After you use the inspect feature to edit and make required changes to the website design, your developers can simply copy the entire altered code under the CSS tab in the Activities Panel. This can then be pasted directly into the code of live website files.',
  },
  {
    id: nanoid(),
    q: 'Do clients need to have an account on ruttl to view projects?',
    a: 'Absolutely not! Once your project is ready to share, simply click on the share button inside the project page. In the new screen, click on the ‘Link’ text. The shareability toggle should be turned on and the link will be copied to your clipboard. Now all you need to do is share the link with your clients to get the client feedback on the project.',
  },
  {
    id: nanoid(),
    q: 'Does ruttl support task management?',
    a: (
      <>
        No, ruttl does not support task management. If you’re looking for the
        same, we would recommend checking out
        <a href="https://brutask.com" target="_blank" rel="noopener noreferrer">
          &nbsp;Brutask&nbsp;
        </a>
        . It is a simple online to-do list for individuals and small teams. Add,
        prioritize and time box your tasks to help improve your productivity.
      </>
    ),
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));
const ForSoftwareCompanies = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="The #1 Website Feedback Tool for Software Companies! | ruttl"
          description="Get quick feedback from your customers with ruttl and build winning softwares - best visual feedback and reporting tool."
          url="https://ruttl.com/usecases/for-software-companies/"
        />
        <main className="usecases-styled-main">
          <section className="features-hero custom-padding ">
            <div className="container">
              <h1 className="mx-auto" style={{ maxWidth: 840 }}>
                Build winning software that your customers love
              </h1>
              <p>Collect effective visual feedback from your customers</p>
              <div
                className="reveal-1 button-container"
                style={{ margin: '30px 0' }}>
                <a href="https://web.ruttl.com" className="button">
                  Get Started for Free
                </a>
              </div>
            </div>
            <TrustedBy />
          </section>
          <div className="section features-main" id="features">
            <div className="container">
              <h2 className="center mt-40">Features</h2>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/leave-comments-website.jpg"
                        alt="illustration of leaving comment on live website"
                        title="illustration of leaving comment on live website"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-50px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '420px' }}>
                        Respond to issues faster
                      </h2>
                      <p>
                        In case of a bug or a design issue, quickly make the
                        edits on live websites and recommend suggestions with
                        the help of text and video comments.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Edit Live Websites
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <video
                        src="/assets/img/guest-commenting.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-30px)' }}>
                      <h2 className="feature-head">
                        Allow customer commenting
                      </h2>
                      <p>
                        Without signing up or logging in to Ruttl, your
                        customers can leave comments, suggestions, and feedback
                        on certain areas of your software.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Try Client Commenting
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/comments-resolved.png"
                          alt="illustration of comments resolved after done"
                          title="illustration of comments resolved after done"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2 className="feature-head">
                        Assign feedback to your team
                      </h2>
                      <p>
                        Invite your team and assign feedback comments to them
                        and resolve issues faster leading to customer
                        satisfaction.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Collaborating
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/kan-ban-style-cards.png"
                          alt="illustration of comments resolved after done"
                          title="illustration of comments resolved after done"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2 className="feature-head">
                        Get notified of new issues
                      </h2>
                      <p>
                        Never miss out on any important updates by getting
                        notified of new comments and suggestions and integrating
                        Ruttl with Slack, Trello, and Asana.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Check Out The Integrations
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </div>
          <TestimonialsSlider />
          <NewFaq data={faqData} />
        </main>
      </>
    )}
  </Layout>
);

ForSoftwareCompanies.propTypes = {
  location: PropTypes.object,
};

export default ForSoftwareCompanies;
